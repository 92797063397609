import React from "react";
import servicedetails1 from "@/images/services/service-detail1.jpg";
import Img from "./img";

const ServicePost1 = () => {
  return (
    <div className="service_details_left">
      <Img
        src={servicedetails1}
        alt="Service Details Image"
        title="Service Details Image"
      />
      <h2 style={{ fontSize: "40px" }}>Mosquito Extermination</h2>
      <p>
        If you ask anyone who has been severely irritated by a bug, they will
        surely name the mosquitoes. It is not only their biting but singing near
        your year. In Hong Kong, mosquitoes are present everywhere. The probable
        reason behind this is that mosquitoes love humid weather, along with
        places where rainfall is a given. Both these features are present in
        Hong Kong. No wonder mosquitoes have a significant kingdom in Hong Kong
        and every. The biggest fear is that some type of mosquitoes can bring
        life-threatening illnesses to humans.
      </p>
      <h3>Problems with Mosquitoes</h3>
      <li>
        They are a reputed carrier of some lethal sicknesses like dengue,
        malaria & so on.
      </li>
      <li>
        Mosquitoes can trigger discomfort and itchiness due to their annoying
        bites.
      </li>
      <li>
        They can easily increase in numbers due to their rapid reproducing
        capability.
      </li>
      <li>Mosquitoes often interrupt your outdoor events.</li>
      <li>For some, their bites can render allergic responses.</li>
      <li>They can reproduce wherever there is water.</li>
      <br />
      <h3>Way to Exterminate Them</h3>
      <li>
        At first, we identify the places where they have been reproducing and
        apply our planet-friendly repellent spray to destroy the breeding place.
      </li>
      <li>
        Then, our technicians, based on the severity of mosquitoes' existence in
        an area, place sustainable traps in different regions to annihilate
        their larvae and aedes.
      </li>
      <li>
        Lastly, we keep monitoring the results to know whether additional traps
        should be set up to further reduce their numbers.
      </li>
    </div>
  );
};

export default ServicePost1;
