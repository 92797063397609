import React from "react";
import Layout from "@/components/layout";
import HeaderOne from "@/components/header-one";
import MenuContextProvider from "@/context/menu-context";
import PageHeader from "@/components/page-header";
import Footer from "@/components/footer";
import ServiceDetails1 from "@/components/service-details1";
import { Helmet as Head } from "react-helmet";

const ServiceDetailsPage1 = () => {
  return (
    <MenuContextProvider>
      <Layout PageTitle="Service Details">
        <Head>
          <title>Mosquito Extermination | Nifixeci</title>
          <meta
            name="description"
            content="Our mosquito extermination solutions have successfully satisfied our
            clients with their lasting efficacy."
          />
        </Head>
        <HeaderOne />
        <PageHeader title="Mosquito Extermination" name="Service" />
        <ServiceDetails1 />
        {/* <ContactTwo /> */}
        <Footer />
      </Layout>
    </MenuContextProvider>
  );
};

export default ServiceDetailsPage1;
